

a{
  text-decoration: none;
  color:#222;
}
::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
padding:5px;
margin:5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.3); 
  border-radius: 5px;
 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #222; 
}